<template>
	<div class="d-flex align-items-center h-100">
		<b-container style="max-width: auto;">
			<div class="no-results rounded mt-5 p-5 text-center">
				<div class="h1 mb-2">You done messed up, A-A-Ron!</div>
				<div class="lead text-muted">We were unable to find this page.</div>
				<b-button variant="primary" v-if="isAuthenticated" to="/" class="mt-5">
					Go to Workspace
					<span class="material-icons-outlined">chevron_right</span>
				</b-button>
				<b-button variant="primary" v-else to="/" class="mt-5">
					Return to Home
					<span class="material-icons-outlined">chevron_right</span>
				</b-button>
			</div>
		</b-container>
	</div>
</template>

<script>
	export default {
		name: 'NotFound',
		data () {
			return {
			};
		}
	}
</script>

<style lang="scss">
</style>
